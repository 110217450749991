import { overview } from "./overview/locales.en";
import { update } from "./update/locales.en";

export const structure = {
    overview,
    update,

    components: {
        installedRigs: {
            title: 'Installed Rigs',
            slot: 'Slot {number}',
        },

        installedServices: {
            title: 'Installed Services',
            slot: 'Slot {number}',
        },

        resolveStructure: {
            resolveError: 'Could not resolve structure. Make sure you have docking permission.',
            resolveStructure: 'Resolve structure ID',
            structureIdPlaceholder: 'Structure ID',
        },

        structureRigSelector: {
            clear: 'Clear',
        },

        structureServiceSelector: {
            clear: 'Clear',
        },
    },

    modals: {
        resolveStructureInfo: {
            title: 'Resolve Structure ID',
            structureInfo: '1. Open the info of the structure',
            dragStructure: '2. Drag the image of the structure into a chat and send the message',
            copy: '3. Right Click > Copy',
            insert: '4. Paste into the Textbox',
        },
    },

    create: {
        generalInformation: {
            description: 'Based on the given Structure ID the Name, System and Structure Type will be automatically determined. This requires that you have docking access to the strucutre.',
            group: 'Group the structure is available in. Multiple possible',
            structureId: 'EVE Structure ID',
            structureName: 'Name of the structure',
            structureNamePlaceholder: 'Structure Name',
            structureType: 'Structure Type',
            system: 'Structure System',
            title: 'General Information',
        },
        title: 'Create Structure',
        createStructure: 'Create',
        createSuccess: 'Structure added successfully',
        createError: 'Error adding structure',
        createErrorMessage: 'Unexpected error while adding the structure.',
    },
}
